<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">主页</el-breadcrumb-item>
      <el-breadcrumb-item>咨询评论管理</el-breadcrumb-item>
      <el-breadcrumb-item>评论黑名单</el-breadcrumb-item>
    </el-breadcrumb>

    <el-card>
      <el-row :gutter="30">
        <el-form
          :model="queryinfo"
          ref="queryinfoRef"
          :inline="true"
          style="text-align: left; margin-left: 40px"
        >
          <el-form-item prop="queryuserID">
            <div style="display: inline-block" class="divSpan">用户ID：</div>
            <div style="display: inline-block">
              <el-input
                placeholder="请输入"
                v-model.trim="queryinfo.queryuserID"
                clearable
                @clear="getResourceList"
              >
              </el-input>
            </div>
          </el-form-item>
          <el-button type="primary" icon="el-icon-search" @click="seachChange"
            >查询</el-button
          >

          <el-button
            type="danger"
            style="float: right; margin-right: 100px"
            @click="addBlack"
            >新增黑名单</el-button
          >
        </el-form>
      </el-row>

      <el-row :gutter="30" style="display: flex">
        <el-table
          :data="ResourceList"
          :header-cell-style="{
            textAlign: 'center',
            background: '#FAFAFA',
            color: 'rgba(0, 0, 0, 0.85)',
            fontWeight: '500',
          }"
          tooltip-effect="dark"
          :cell-style="{
            textAlign: 'center',
          }"
        >
          <el-table-column type="index"></el-table-column>
          <el-table-column
            prop="userId"
            label="用户ID"
            min-width="150px"
          ></el-table-column>
          <el-table-column
            prop="userName"
            label="用户名"
            min-width="100px"
          ></el-table-column>

          <el-table-column
            prop="phone"
            label="手机号"
            min-width="200px"
          ></el-table-column>
          <el-table-column
            prop="cause"
            label="黑名单原因"
            min-width="200px"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="registerTime"
            label="注册时间"
            min-width="150px"
          ></el-table-column>
          <el-table-column
            prop="blackTime"
            label="移入时间"
            min-width="150px"
          ></el-table-column>

          <el-table-column label="操作" min-width="100px">
            <template slot-scope="scope">
              <el-button
                type="primary"
                size="mini"
                @click="deleteBlack(scope.row)"
                >移出黑名单
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-row>
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryinfo.pageNum"
        :page-size="queryinfo.pageSize"
        layout="total, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>

    <el-dialog
      :close-on-click-modal="false"
      title="新增黑名单"
      :visible.sync="addDialogVisible"
      width="35%"
      @close="addDialogClosed"
    >
      <el-form
        :model="addForm"
        :rules="addFormRules"
        ref="addFormRef"
        label-width="120px"
      >
        <el-form-item label="用户ID：" prop="userid">
          <el-input
            v-model.trim="addForm.userid"
            placeholder="请输入"
          ></el-input>
        </el-form-item>
        <el-form-item label="黑名单原因：" prop="cause">
          <el-input
            type="textarea"
            v-model.trim="addForm.cause"
            placeholder="请输入"
            maxlength="200"
            @input="DesText"
            show-word-limit
            clearable
            rows="4"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取消</el-button>
        <el-button type="primary" @click="confirmAdd">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { getBlackList, insertBlack, updateBlack } from '@/api/cloudmarket/commentInfo'

export default {
  data () {
    return {
      queryinfo: {
        queryuserID: '',
        pageNum: 1,
        pageSize: 8
      },
      total: 0,
      ResourceList: [],
      addDialogVisible: false,
      addForm: {
        userid: '',
        cause: ''
      },
      addFormRules: {
        userid: [
          { required: true, message: '内容不能为空', trigger: 'blur' }
        ],
        cause: [
          { required: true, message: '内容不能为空', trigger: 'blur' }
        ],
      },
      wordnum: 0,
    }
  },
  mounted () {
    this.getResourceList()
  },
  methods: {
    async getResourceList () {
      const res = await getBlackList(this.queryinfo.pageNum, this.queryinfo.pageSize, this.queryinfo.queryuserID)
      if (res.status !== 200) return this.$message.error('获取失败')
      if (res.data.status !== 200) return this.$message.error(res.data.message)
      this.ResourceList = res.data.data.list
      this.total = res.data.data.total
    },
    addBlack () {
      this.addDialogVisible = true
    },
    DesText () {
      this.wordnum = this.addForm.cause.length
    },
    confirmAdd () {
      this.$refs.addFormRef.validate(async valid => {
        if (valid) {
          const res = await insertBlack(this.addForm.userid, this.addForm.cause)
          if (res.status !== 200) return this.$message.error('操作失败')
          if (res.data.status !== 200) return this.$message.error(res.data.message)
          this.$message.success('操作成功')
          this.addDialogVisible = false
          this.getResourceList()
        }
      })
    },
    addDialogClosed () {
      this.$refs.addFormRef.resetFields()
    },
    async deleteBlack (para) {
      const res = await updateBlack(para.id)
      if (res.status !== 200) return this.$message.error('操作失败')
      if (res.data.status !== 200) return this.$message.error(res.data.message)
      this.$message.success('操作成功')
      this.getResourceList()
    },
    seachChange () {
      this.queryinfo.pageNum = 1
      this.getResourceList()
    },
    handleSizeChange (newSize) {
      this.queryinfo.pageSize = newSize
      this.getResourceList()
    },
    handleCurrentChange (newPage) {
      this.queryinfo.pageNum = newPage
      this.getResourceList()
    },
  }
}
</script>
<style lang="less" scoped>
/deep/ .el-form-item__content {
  margin-right: 20px;
}
</style>